import React from 'react';
import './header.scss';
import { connect } from 'react-redux';
import headerImage from '../../assets/images/header.png'

const Header = (props) => {
    return (
        <header className='header-section'>
            <div>
                <img src={headerImage} alt={headerImage} ></img>
            </div>
        </header>
    );
};

Header.propTypes = {};
Header.defaultProps = {};

const actionCreators = {};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Header);
