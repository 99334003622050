import React from 'react';
import './footer.scss';
import { connect } from 'react-redux';
import { updateUserData } from '../../redux/action/user'

import linkedinImg from '../../assets/images/linkedin.png'
import instagramImg from '../../assets/images/instagram.png'

const Footer = (props) => {

    return (
        <div className='footer-section'>
            <div className='gridContainer'>
                <div className='inner-footer-section'>
                    <div className='menu-list-section'>
                        <ul>
                            <li>
                                <a href='https://japa.health/japa-health-privacy-policy/' target='_blank' rel="noreferrer" alt='Privacy Policy'>Privacy Policy</a>
                            </li>
                            <li>
                                <a href='https://japa.health/japa-health-terms-of-use/' target={'_blank'} rel="noreferrer" alt='Terms of use'>Terms of use</a>
                            </li>
                            <li>
                                <a href='https://japa.health/japa-health-faqs/' target={'_blank'} rel="noreferrer" alt='FAQ'>FAQ's</a>
                            </li>
                        </ul>
                    </div>
                    <div className='copyright-section'>
                        <p>All rights reserved. © Copyright 2022</p>
                    </div>
                    <div className='social-section'>
                        <ul>
                            <li>
                                <a href='https://www.linkedin.com/company/japa-health/' target={'_blank'} rel="noreferrer" alt='Linkedin'>
                                    <img src={linkedinImg} alt={linkedinImg}></img>
                                </a>
                            </li>
                            <li>
                                <a href='http://instagram.com/japa.health' target={'_blank'} rel="noreferrer" alt='Instagram'>
                                    <img src={instagramImg} alt={instagramImg}></img>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

Footer.propTypes = {};
Footer.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Footer);
