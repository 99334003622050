import React, { useEffect, useState } from "react";
import "./header.scss";
import { connect } from "react-redux";
import headerImage from "../../../assets/images/header.png";
import LabelInput from "../../../atoms/label";
import ProgressBar from "../../../atoms/progressBar";
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router";

const Header = (props) => {
  const [progress, setProgress] = useState(0);
  const [answredGroup, setAnsworGroup] = useState(0);

  useEffect(() => {
    if (props.user?.totalDetermine.length > 0) {
      const answedDetermin = props.user?.totalDetermine.filter(
        (obj) => obj.isFillUp === true
      ).length;
      if (props.user?.totalDetermine.length > answedDetermin) {
        const progrsss =
          ((answedDetermin + 1) / props.user?.totalDetermine.length) * 100;
        setAnsworGroup(answedDetermin + 1);
        setProgress(progrsss);
      } else {
        setAnsworGroup(props.user?.totalDetermine.length);
        setProgress(100);
      }
    }
  }, [props.user?.totalDetermine]);

  const navigate= useNavigate();

  function signout() {
    localStorage.clear('userKey')
    localStorage.clear('_grecaptcha')
    sessionStorage.clear('_grecaptcha')
    // console.log(">>>>>>>>>>>>>>>>>Logging out user<<<<<<<<<<<<<<<<<<<<<");
    navigate('/signin')
    alert('Hi! Your session has expired due to inactivity. Please Sign in again to continue the assessment')
    window.location.reload();      
  }

  useEffect(()=> {
    // console.log(">>>>>>>>>>logout timer begins")
    
    // Set a timeout to log out the user
    const logoutTimer = setTimeout(() => {
      signout();
    }, 1000 * 60 * 2);  //Time in Millisceonds Seconds Minutes Hours Format

    return () => clearTimeout(logoutTimer);
  });

  useEffect(() => {
    // Add a beforeunload event listener
    window.addEventListener("beforeunload", logout);

    // Remove the event listener when the component unmounts
    return () => window.removeEventListener("beforeunload", logout);
  }, []);

  // Log out the user when the window is about to be closed
  const logout = () => {
    // Send a logout request to the server
    signout();
  };

  return (
    <div className="progress-header-section">
      <div className="progress-header-section__fixed">
        <div className="gridContainer">
          <div className="inner-progress-header__fixed">
            <div>
              <img src={headerImage} alt={headerImage}></img>
            </div>
            <div className="cus-label progress-header-section__right-side">
              <div>
                {/* <img className='right-side-image' src={userThumb} alt={userThumb} ></img> */}
              </div>
              <div className="progress-header-section__right-side--userdetail">
                <div>Welcome</div>
                <div>{props.user.firstName}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="progress-header-section__body-header">
        <div className="gridContainer">
          <div className="inner-progress-header__fixed--bottom">
            <h3 className="inner-progress-header__fixed--bottom--left">
              <LabelInput
                text={props.user.language?.questionList?.title + " : "}
                className={"header-title"}
              />
              <LabelInput
                className={"header-title"}
                text={props.user?.activeGroup || ""}
              />
            </h3>
            <div className="inner-progress-header__fixed--bottom--right">
              {props.user?.questionList?.length > 0 && (
                <>
                  <div className="left-side-progress">
                    <ProgressBar progressValue={progress} />
                  </div>
                  <h4 style={{ display: "flex", marginLeft: "10px" }}>
                    <LabelInput
                      text={`${
                        answredGroup > 9 ? answredGroup : "0" + answredGroup
                      }`}
                    />
                  </h4>
                  <LabelInput
                    text={`/${
                      props.user?.totalDetermine?.length > 9
                        ? props.user?.totalDetermine?.length
                        : "0" + props.user?.totalDetermine?.length
                    } ${props.user.language?.questionList?.sections}`}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {};
Header.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Header);
