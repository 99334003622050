import { SET_USER_DATA } from "../../constants/ActionTypes";
import axios from "axios";
import { api_key, sns_api_key } from "../../constants/config";

export function setUserData(content) {
	return {
		type: SET_USER_DATA,
		content,
	};
}

export function updateUserData(obj) {
	return (dispatch) => {
		dispatch(setUserData(obj));
	};
}

export function getQuestionList() {
	return (dispatch, getState) => {
		const { user } = getState();

		if (user._id === '') {
			return;
		}

		dispatch(setUserData({ questionError: "" }));
		axios.post(api_key + 'question', { userId: user._id }).then((res) => {
			if (res.status === 200) {
				let countDeterminArray = []
				res.data.map((obj, index) => {
					if (countDeterminArray.filter((subObj) => subObj.name === obj.Determinant).length === 0) {
						countDeterminArray.push({
							name: obj.Determinant,
							isFillUp: false
						});
					}
					return '';
				});

				dispatch(setUserData({ questionList: res.data, totalDetermine: countDeterminArray, isNewUser: false }));
			}
		}).catch(err => {
			dispatch(setUserData({ questionError: "Some Error Please refresh page or check Data" }));
		})
	};
}

export function signUpUser(object) {
	return (dispatch) => {

		localStorage.removeItem('progressDone');
		localStorage.removeItem("detailDone");
		dispatch(setUserData({ registrationError: "", isLoading: true }));
		let bodyData = {
			firstName: object.firstName,
			lastName: object.lastName,
			emailaddress: object.emailaddress,
			mobileNumber: object.mobileNumber,
			countryCode: object.countryCode,
			hostName: object.hostName,
			// marketingInfo: object.marketingInfo,
		}
		axios.post(api_key + 'register', bodyData).then(res => {
			if (res.status === 200) {
				const tempObj = {
					"hash": res.data.hash
				}
				dispatch(setUserData({ ...tempObj, status: 'otp', isLoading: false }));
			} else {
				dispatch(setUserData({ registrationError: res.data.message, isLoading: false }));
			}
		}).catch(err => {
			dispatch(setUserData({ registrationError: "Some Error Please refresh page or check Data", isLoading: false }));
		})
	}
}

export function updateUser(object, openModal) {
	return (dispatch) => {
		dispatch(setUserData({ profileError: "", isLoading: true }));
		axios.post(api_key + 'profile', object).then(res => {
			if (res.status === 200) {
				dispatch(setUserData({...res.data, isLoading: false}));
				if (openModal) {
					openModal();
				}
			} else {
				dispatch(setUserData({ profileError: res.data.message, isLoading: false }));
			}
		}).catch(err => {
			dispatch(setUserData({ profileError: "Some Error Please refresh page or check Data", isLoading: false }));
		})
	}
}

export function updateQuestionAnswer(object) {
	return (dispatch, getState) => {
		dispatch(setUserData({ answeredData: "" }));
		axios.post(api_key + 'question/userdatasave', object).then(res => {
			if (res.status === 200) {
				let { user } = getState();
				dispatch(setUserData({ answeredData: (user.answeredData ? user.answeredData + 1 : 1) }));
			} else {
				dispatch(setUserData({ questionError: res.data.message }));
			}
		}).catch(err => {
			dispatch(setUserData({ questionError: "Some Error Please refresh page or check Data" }));
		})
	}
}

export function loginUser(object) {
	return (dispatch) => {
		dispatch(setUserData({ loginError: "", isLoading: true }));
		axios.post(api_key + 'login', object).then(res => {
			if (res.status === 200) {
				const tempObj = {
					"hash": res.data.hash
				}
				dispatch(setUserData({ ...tempObj, /* status: 'otp', */ isLoading: false }));
			} else {
				dispatch(setUserData({ loginError: res.data.message, isLoading: false }));
			}
		}).catch(err => {
			dispatch(setUserData({ loginError: "Some Error Please refresh page or check Data", isLoading: false }));
		})
	}
}

export function verifyOTP(object, navigate) {
	// console.log(object);
	return (dispatch) => {
		dispatch(setUserData({ otpError: "", isLoading: true }));
		axios.post(api_key + 'verifyOtp', object).then(res => {
			if (res.status === 200) {
				if (res.data[0]) {
					localStorage.setItem('userKey', res.data[0].UserID)
					let demo = {
						firstName: res.data[0].Fname,
						lastName: res.data[0].Lname,
						hostName: res.data[0].source,
						// marketingInfo: res.data[0].marketingInfo,
						emailaddress: res.data[0].EmailID,
						mobileNumber: res.data[0].MobileNo,
						countryCode: res.data[0].CountryCode,
						height: res.data[0].Height,
						heightunit: res.data[0].HeightUnit ? res.data[0].HeightUnit : 'Inches',
						weight: res.data[0].Weight,
						weightunit: res.data[0].WeightUnit ? res.data[0].WeightUnit : 'Pounds',
						zipCode: res.data[0].ZipCode,
						age: res.data[0].Age,
						gender: res.data[0].Gender,
						_id: res.data[0].UserID,
						signupForm: {
							firstName: '',
							lastName: '',
							emailaddress: '',
							mobileNumber: '',
							countryCode: '+1',
							hostName: '',
							// marketingInfo: ''
						},
						loginForm: {
							emailaddress: '',
							countryCode: '+1',
							mobileNumber: '',
						},
						otpSuccess: true,
						isLoading: false
					}
					dispatch(setUserData(demo));

					// console.log("res.data[0].isSurveyCompleted",res.data[0].isSurveyCompleted)

					if (res.data[0].isSurveyCompleted === '1') {
						localStorage.setItem('progressDone', true)
					}

					if (demo.height && demo.weight && demo.zipCode && demo.age && demo.gender) {
						localStorage.setItem('detailDone', true)
					}

					if (localStorage.getItem('progressDone') === null) {
						navigate('/progress');
					} else if (localStorage.getItem("detailDone") === null) {
						navigate('/profiledetail');
					} else {
						navigate('/result');
					}

				}
			} else {
				dispatch(setUserData({ otpError: res.data.message, isLoading: false }));
			}
		}).catch(err => {
			if (err?.response?.data?.message) {
				dispatch(setUserData({ otpError: err.response.data.message, isLoading: false }));
			} else {
				dispatch(setUserData({ otpError: "Some Error Please refresh page or check Data", isLoading: false }));
			}
		})
	}
}

export function getUserData(value, navigate) {
	return (dispatch) => {
		dispatch(setUserData({ userData: "", isLoading: true }));
		axios.post(api_key + 'userdata', { id: value }).then(res => {
			if (res.status === 200) {
				if (res.data[0]) {
					localStorage.setItem('userKey', res.data[0].UserID)
					let demo = {
						firstName: res.data[0].Fname,
						lastName: res.data[0].Lname,
						emailaddress: res.data[0].EmailID,
						mobileNumber: res.data[0].MobileNo,
						countryCode: res.data[0].CountryCode,
						hostName: res.data[0].source,
						// marketingInfo: res.data[0].marketingInfo,
						height: res.data[0].Height,
						heightunit: res.data[0].HeightUnit ? res.data[0].HeightUnit : 'Inches',
						weight: res.data[0].Weight,
						weightunit: res.data[0].WeightUnit ? res.data[0].WeightUnit : 'Pounds',
						zipCode: res.data[0].ZipCode,
						age: res.data[0].Age,
						gender: res.data[0].Gender,
						_id: res.data[0].UserID,
					}

					if (res.data[0].isSurveyCompleted === '1') {
						localStorage.setItem('progressDone', true)
					}

					if (demo.height && demo.weight && demo.zipCode && demo.age && demo.gender) {
						localStorage.setItem('detailDone', true)
					}


					dispatch(setUserData(demo));

					if (localStorage.getItem('progressDone') === null) {
						navigate('/progress');
					} else if (localStorage.getItem("detailDone") === null) {
						navigate('/profiledetail');
					} else {
						navigate('/result');
					}
				}
			} else {
				dispatch(setUserData({ userData: res.data.message, isLoading: false }));
			}
		}).catch(err => {
			dispatch(setUserData({ userData: "Some Error Please refresh page or check Data", isLoading: false }));

		})
	}
}

export function getDeterminants() {
	return (dispatch) => {
		dispatch(setUserData({
			isLoading: true,
		}));
		axios.post(sns_api_key + 'userscore', {
			"userId": localStorage.getItem('userKey')
		}).then(res => {
			if (res.status === 200) {
				dispatch(setUserData({ determinantList: res.data.data, userFinalScore: res.data.finalScore, isLoading: false }));
			}
		}).catch(err => {
			console.log("this is Error :: ", err)
			dispatch(setUserData({
				isLoading: false,
			}));
		})
	}
}

export function updateSuggestion(userId, suggestionId) {
	return (dispatch) => {
		axios.post(api_key + 'updateSuggestionDate', { 
			userId: userId, 
			SuggestionID: suggestionId 
		}).then(res => {
			
		}).catch(err => {
			console.log("this is Error :: ", err)
		})
	}
}

export function updateReference(userId, suggestionId) {
	return (dispatch) => {
		axios.post(api_key + 'updateReferenceDate', { 
			userId: userId, 
			SuggestionID: suggestionId 
		}).then(res => {
			
		}).catch(err => {
			console.log("this is Error :: ", err)
		})
	}
}

export function deleteUserResponse(userId, navigate) {
	return (dispatch) => {
		dispatch(setUserData({
			isLoading: true,
		}));
		axios.post(api_key + 'question/deletedatasave', { userId: userId }).then(res => {
			if (res.status === 200) {
				localStorage.removeItem('progressDone');
				localStorage.removeItem("detailDone");
				dispatch(setUserData({
					questionList: [],
					totalDetermine: [],
					determinantList: [],
					height: '',
					heightunit: 'Inches',
					weight: '',
					weightunit: 'Pounds',
					zipCode: '',
					age: '',
					gender: '',
					isLoading: false,
					otpSuccess: false,
					userFinalScore: 0,
					isNewUser: false
				}));
				navigate('/progress');
			}
		}).catch(err => {
			console.log("this is Error :: ", err)
			dispatch(setUserData({
				isLoading: false,
			}));
		})
	}
}
