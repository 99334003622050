import React from 'react';
import './resultHeader.scss';
import { connect } from 'react-redux';
import headerImage from '../../../assets/images/header.png'
import bgImage from '../../../assets/images/result-bg-header.png'

const ResultHeader = (props) => {

    return (
        <div className='result-header-section'>
            <div className='ab-bg-image'>
                <img src={bgImage} alt={bgImage} ></img>
            </div>
            <div className='result-header-section__fixed bg-transparent border-none'>
                <div className='gridContainer'>
                    <div className='inner-result-header__fixed'>
                        <div>
                            <img src={headerImage} alt={headerImage} ></img>
                        </div>
                        <div className='cus-label result-header-section__right-side'>
                            <div>
                                {/* <img className='right-side-image' src={userThumb} alt={userThumb} ></img> */}
                            </div>
                            <div className="result-header-section__right-side--userdetail">
                                <div>
                                    Welcome
                                </div>
                                <div>
                                    {props.user.firstName}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

ResultHeader.propTypes = {};
ResultHeader.defaultProps = {};

const actionCreators = {};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(ResultHeader);
