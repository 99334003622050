import React, { useState } from "react";
import "./questionList.scss";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import LabelInput from "../../../atoms/label";
import QuestionOption from "../../../atoms/questionOption";
import {
  updateUserData,
  updateQuestionAnswer,
} from "../../../redux/action/user";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import QuestionCheckBox from "../../../atoms/questionCheckBox";
import QuestionTextBox from "../../../atoms/questionTextBox";
import { Box, Modal } from "@mui/material";
import founderImage from "../../../assets/images/founders_image.jpg";
import ButtonInput from "../../../atoms/button";

const NoShareTitle = styled("span")(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "black" : "white",
  display: "block",
  textAlign: "justify",
  fontWeight: "600",
}));
const NoShareCredit = styled("span")(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "black" : "white",
  fontSize: "15px",
  marginTop: "32px",
  display: "block",
  fontWeight: "600",
  // textAlign: "right",
}));

const NoShareFounderImage = styled("img")(({ theme }) => ({
  height: "140px",
  width: "140px",
  objectFit: "cover",
  objectPosition: "top",
  imageRendering: "-webkit-optimize-contrast",
  // float: "right",
  marginTop: "10px",
  borderRadius: "7px",
}));

const QuestionList = (props) => {
  let fleg = 1;
  const [isNoDataShare, setIsNoDataShare] = useState(props.user.isNewUser);
  return (
    <div id="questionList" className="questions-list">
      <Modal
        open={isNoDataShare}
        onClose={() => {
          setIsNoDataShare(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "550px",
            maxWidth: "75%",
            bgcolor: "#0e1b1d",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "10px",
            p: 4,
          }}
        >
          <div className="no-share-modal">
            <NoShareTitle>
              {props.user.language.questionList.no_sharing_modal.main_text1}
            </NoShareTitle>
            <ul>
              <li>
                {props.user.language.questionList.no_sharing_modal.main_text2}
              </li>
              <li>
                {props.user.language.questionList.no_sharing_modal.main_text3}
              </li>
              <li>
                {props.user.language.questionList.no_sharing_modal.main_text4}
              </li>
              <li>
                {props.user.language.questionList.no_sharing_modal.main_text5}
              </li>
            </ul>
          </div>
          <div>
            <NoShareCredit>
              {props.user.language.questionList.no_sharing_modal.credit}
            </NoShareCredit>
            <div
              style={{
                display: "flex",
              }}
            >
              <NoShareFounderImage src={founderImage} />
              <ButtonInput
                class={`questions-list__modal_next`}
                text={props.user.language.questionList.no_sharing_modal.next}
                style={{
                  padding: "4px 20px !important",
                }}
                onSubmit={() => {
                  setIsNoDataShare(false);
                }}
              />
            </div>
          </div>
        </Box>
      </Modal>
      {props.questionList.map((mainObj, index) => {
        return (
          <div key={index}>
            {props.showCongFleg === mainObj.DisplayOrder && (
              <div className="congrats-block margin-top-20">
                <div className="congrats-block__left-side">
                  <CheckRoundedIcon style={{ color: "#fff" }} />
                </div>
                <div className="congrats-block__right-side">
                  {props.questionList.find(
                    (subObj) => subObj.DisplayOrder === props.showCongFleg - 1
                  )?.SuccessMessage ? (
                    <h3>
                      {
                        props.questionList.find(
                          (subObj) =>
                            subObj.DisplayOrder === props.showCongFleg - 1
                        )?.SuccessMessage
                      }
                    </h3>
                  ) : (
                    <>
                      <h3>
                        👏 Way to go, you have completed the{" "}
                        {
                          props.questionList.find(
                            (subObj) =>
                              subObj.DisplayOrder === props.showCongFleg - 1
                          )?.Determinant
                        }
                        .
                      </h3>
                      <p>
                        Section to reveal how to manage your exposure to
                        environmental, nutritional and emotional toxins.
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
            <div id={`questionIndex${index}`}>
              {mainObj.DisplayOrder <= props.questionIndex && (
                <div>
                  <div
                    id={`questionList${index}`}
                    className={`questions-list__question margin-top-20 ${
                      mainObj.DisplayOrder === props.questionIndex
                        ? "questions-list__question__active"
                        : ""
                    }`}
                  >
                    <div className="questions-list__question--main">
                      <div className="questions-list__question--main--count ">
                        <LabelInput
                          className="question-title"
                          text={`${fleg++}.`}
                        />
                      </div>
                      <div className="questions-list__question--main--question">
                        <LabelInput
                          className="break-text question-title font-transform"
                          // requireField={subObj.isMandatory === '1' ? true : false}
                          text={mainObj.QuestionText}
                        />
                        {mainObj.QuestionType === "Single" ? (
                          <QuestionOption
                            answerIndex={index}
                            option={mainObj.options}
                            questionIndex={mainObj.userQuestionID}
                            defaultValue={mainObj.Response[0]}
                            displayOrder={mainObj.DisplayOrder}
                            valueChange={props.valueChange}
                          />
                        ) : mainObj.QuestionType === "Multi" ? (
                          <div>
                            <QuestionCheckBox
                              answerIndex={index}
                              option={mainObj.options}
                              questionIndex={mainObj.userQuestionID}
                              defaultValue={mainObj.Response}
                              displayOrder={mainObj.DisplayOrder}
                              valueChange={props.valueChange}
                            />
                          </div>
                        ) : mainObj.QuestionType === "Text" ? (
                          <QuestionTextBox
                            answerIndex={index}
                            option={mainObj.options}
                            displayOrder={mainObj.DisplayOrder}
                            questionIndex={mainObj.userQuestionID}
                            type={"text"}
                            defaultValue={mainObj.Response}
                            valueChange={props.valueChange}
                            placeholder={
                              props.user.language?.questionList
                                ?.textboxPlaceholder
                            }
                            buttonText={
                              props.user.language?.questionList
                                ?.textboxButtonText
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

QuestionList.propTypes = {};
QuestionList.defaultProps = {};

const actionCreators = {
  updateUserData,
  updateQuestionAnswer,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(QuestionList);
