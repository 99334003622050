import {
	SET_USER_DATA,
} from '../../constants/ActionTypes';

import enLanguage from '../../assets/language/en.json'

const INITIAL_STATE = {
	language: enLanguage,

	signupForm: {
		firstName: '',
		lastName: '',
		emailaddress: '',
		mobileNumber: '',
		countryCode: '+1',
		hostName: '',
		// marketingInfo: '',
	},
	loginForm: {
		emailaddress: '',
		countryCode: '+1',
		mobileNumber: '',
		parsedMobileNumber: '',
	},

	status: 'signup',
	isNewUser: false,

	firstName: '',
	lastName: '',
	emailaddress: '',
	mobileNumber: '',
	countryCode: '+1',
	hostName: '',
	// marketingInfo: '',

	_id: '',

	height: '',
	heightunit: 'Inches',
	weight: '',
	weightunit: 'Pounds',

	zipcode: '',

	hash: '',
	age: '',
	gender: '',

	userFinalScore: 0,
	questionList: [],
	totalDetermine: [],

	isLoading: false,
};

const user = (state = INITIAL_STATE, action = {}) => {
	switch (action.type) {
		case SET_USER_DATA:
			return {
				...state,
				...action.content,
			};
		default:
			return state;
	}
};

export default user;
